import React, { useEffect } from 'react';
import { OrbitControls } from "@react-three/drei";
import { useThree } from 'react-three-fiber';
import { Vector3 } from 'three';

export function Cam({ settings }) {
  const { camera } = useThree(state => state.camera);

  useEffect(() => {
    camera.position.set(...settings.position);
    camera.updateProjectionMatrix();
  }, [settings]);

  return <>
    <OrbitControls
      maxPolarAngle={Math.PI / 2 + 0.1}
      minPolarAngle={Math.PI / 3}
      target={settings.target}
    />
  </>;
}
