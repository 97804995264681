import create from 'zustand';

export const useStore = create((set) => ({
  step: 0,
  word: [],
  userId: '',
  attemptsLeft: 0,
  won: undefined,
  setStep(n) {
    set({ step: n });
  },
  setUserId(id) {
    set({ userId: id });
  },
  setWord(w) {
    set({ word: w });
  },
  setAttemptsLeft(n) {
    set({ attemptsLeft: n });
  },
  setWon(won) {
    set({ won });
  },
  reset() {
    set({
      step: 0,
      word: [],
      userId: '',
      attemptsLeft: 0,
      won: undefined,
    })
  }
}));
