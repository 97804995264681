import { useEffect, useState } from "react";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { Object3D } from "three";

/** loads model and returns geometry, materials and animations */
export function useGetModel(path, id) {
  const [instance, setInstance] = useState(new Object3D());
  const loader = new FBXLoader();
  useEffect(() => {
    // asyncronously load model
    loader.loadAsync(`/models/${path}.fbx`).then((group) => {
      setInstance(group);
    });
  }, [id, path]);
  useEffect(() => {
    instance.traverse((o) => {
      // enable shadows
      if (o.isMesh) {
        o.castShadow = true;
        o.receiveShadow = true;
      }
    });
  }, [instance]);

  return instance;
}
