import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStore } from './store';

import './styles.css';

function getMessages(length) {
  return [
    'AAAA! Someone\'s trying to break into my room! A maniac!',
    'Quick! I need to find a gun in this box!',
    `Damn! There\'s a safe inside it. And I don\'t remember the pass word! I need to guess. I remember that the word was ${length} letters long. Let me guess...`,
    'Wrong word! I\'d better be careful, or else...'
  ];
}


const HOST = 'https://saltykov-go.herokuapp.com';
async function init() {
  const data = await fetch(`${HOST}/start`);
  return await data.json();
}

async function guess(letter, userId) {
  const data = await fetch(
    `${HOST}/guess`,
    {
      method: 'POST',
      headers: {
        'X-Client-Id': userId,
      },
      body: JSON.stringify({
        letter,
      })
    }
  );
  return await data.json();
}

export function UI() {
  const step = useStore(state => state.step);
  const word = useStore(state => state.word);
  const userId = useStore(state => state.userId);
  const attemptsLeft = useStore(state => state.attemptsLeft);
  const won = useStore(state => state.won);
  const setStep = useStore(state => state.setStep);
  const setWord = useStore(state => state.setWord);
  const setUserId = useStore(state => state.setUserId);
  const setAttemptsLeft = useStore(state => state.setAttemptsLeft);
  const setWon = useStore(state => state.setWon);
  const reset = useStore(state => state.reset);
  const messages = useMemo(() => getMessages(word.length), [word]);

  useEffect(() => {
    switch (step) {
      case 0:
        init().then(({ id, wordLength }) => {
          setWord(new Array(wordLength).fill('_'));
          setAttemptsLeft(wordLength);
          setUserId(id);
        });
        break;
    }
  }, [step]);

  const handleKey = async ({ key, keyCode }) => {
    if (step === 2 && keyCode >= 63 && keyCode <= 90) {
      try {
        const { letterExist, attemptsLeft, word, won } = await guess(key, userId);
        setWord(word.split(''));
        setAttemptsLeft(attemptsLeft);
        if (!letterExist) {
          setStep(3);
        }
        if (won) {
          setWon(true);
          setStep(-1);
        }
        setAttemptsLeft(attemptsLeft);
      } catch (er) {
        setWon(false);
        setStep(-1);
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleKey);
    return () => window.removeEventListener('keyup', handleKey);
  }, []);

  return <div className="message" key={step}>
    {messages[step]}
    {(step < 2 && won === undefined) && <button onClick={() => setStep(step + 1)}>next</button>}
    {step === 2 && (
      <>
        <div className="guess">
          {word.map((letter, index) => {
            return <span className="letter" key={index}>{letter}</span>
          })}
          <div className="hint">
            press any letter on the keyboard
          </div>
          <div className="hint">
            You have {attemptsLeft} attempts left
          </div>
        </div>
      </>
    )}
    {step === 3 && (
      <button onClick={() => setStep(2)}>next attempt</button>
    )}
    {won === true && (<>
      <div className="guess">You won!</div>
      <button onClick={() => {
        setStep(0);
        reset();
      }}>start over</button>
    </>)}
    {won === false && (<>
      <div className="guess">You lost!</div>
      <button onClick={() => {
        setStep(0);
        reset();
      }}>start over</button>
    </>)}
  </div>
}
