import React, { Suspense, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { Canvas } from "react-three-fiber";
import { PCFShadowMap, PCFSoftShadowMap, Vector3 } from "three";

import "./index.css";
import Room from "./Room";
import { NPC } from "./NPC";
import { getRotation } from "./helpers/getRotation";
import { UI } from "./UI";
import { Cam } from "./Cam";
import { useStore } from "./store";

const scale = [0.35, 0.35, 0.35];
const cameraPos = {
  default: [-200, 75, 200],
  behindEnemy: [260, 95, 20],
  enemy: [-40, 40, 5],
  shoot: [-35, 55, 5],
};
const enemyPos = new Vector3(200, 0, 20);

const enemyRotation = getRotation(new Vector3(-1, 0, 0));

function Game() {
  const step = useStore(state => state.step);
  const won = useStore(state => state.won);

  const playerRotation = useMemo(() => {
    const dir = won === true ? new Vector3(1, 0, 0) : new Vector3(-1, 0, -1);
    return getRotation(dir);
  }, [won]);

  const cameraSettings = useMemo(() => {
    if (won === false) {
      return {
        position: cameraPos.enemy,
        fov: 35,
        target: enemyPos,
      };
    } else if (won === true) {
      return {
        position: cameraPos.shoot,
        fov: 35,
        target: enemyPos,
      };
    }
    if ([1, 2].includes(step)) {
      return {
        position: cameraPos.default,
        fov: 35,
        target: [0, 0, 0],
      };
    }

    if (step === 3) {
      return {
        position: cameraPos.behindEnemy,
        fov: 35,
        target: [0, 0, 0],
      }
    }

    return {
      position: cameraPos.enemy,
      fov: 35,
      target: enemyPos,
    };
  }, [step, won]);

  const playerState = useMemo(() => {
    if (won === true) {
      return 'finish';
    }
    return step > 0 && step < 3 ? 'picking' : 'terrified';
  }, [step]);
  const enemyState = useMemo(() => {
    if (won === false) {
      return 'finish';
    }
    return step > 1 && step < 3 ? 'idle' : 'attacking';
  }, [step, won]);


  return (
    <>
      <UI key={step} />
      <Canvas
        camera={cameraSettings}
        onCreated={({ gl }) => {
          gl.shadowMap.enabled = true;
          gl.shadowMap.type = PCFSoftShadowMap;
        }}
      >
        <Cam settings={cameraSettings} />
        <Suspense fallback={null}>
          <Room />
          <group quaternion={playerRotation}>
            <NPC id="player" model="hero" scale={scale} state={playerState} />
          </group>
          <group position={enemyPos} quaternion={enemyRotation}>
            <NPC id="enemy" model="enemy" scale={scale} state={enemyState} />
          </group>

        </Suspense>
        <pointLight
          position={[-30, 75, -30]}
          intensity={0.5}
          color="white"
          castShadow
        />
        <pointLight
          position={[-30, 5, 30]}
          intensity={0.25}
          color={"blue"}
          castShadow
        />
        {/* <pointLight
          position={cameraPos.enemy}
          intensity={0.5}
          color="white"
          castShadow
        /> */}
      </Canvas>
    </>
  );
}

var mountNode = document.getElementById("root");
ReactDOM.render(<Game />, mountNode);
