/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: jimbogies (https://sketchfab.com/jimbogies)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/storage-room-ab0268dc8752414d82ab3e2f10520894
title: Storage Room
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Room({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/models/room/scene.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group position={[-107.29, -301.92, 60.89]} rotation={[1.5, -0.33, -0.02]} />
        <lineSegments geometry={nodes.Material2.geometry} material={nodes.Material2.material} />
        <mesh receiveShadow geometry={nodes.Material2_1.geometry} material={materials.Stucco} />
        <mesh receiveShadow geometry={nodes.Material2_2.geometry} material={materials.Block} castShadow />
        <mesh receiveShadow geometry={nodes.Material2_3.geometry} material={materials.Metal} />
        <mesh receiveShadow geometry={nodes.Material2_4.geometry} material={materials.Ceiling} />
        <mesh receiveShadow geometry={nodes.Material2_5.geometry} material={materials.Floor} />
        <mesh receiveShadow geometry={nodes.Material2_6.geometry} material={materials.Metal_scratched_008_basecolor} />
        <mesh receiveShadow geometry={nodes.Material2_7.geometry} material={materials.Metal_Grill_010_basecolor} />
        <mesh receiveShadow geometry={nodes.Material2_8.geometry} material={materials.Light} />
        <mesh receiveShadow geometry={nodes.Material3.geometry} material={materials.material} />
        <mesh receiveShadow geometry={nodes.Material2_9.geometry} material={materials.Blackout} />
        <mesh receiveShadow geometry={nodes.Material2_10.geometry} material={materials.Switch} />
        <mesh receiveShadow geometry={nodes.Material2_11.geometry} material={materials.Cardboard} castShadow />
      </group>
    </group>
  )
}

useGLTF.preload('models/room/scene.gltf')
