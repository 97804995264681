import React, { memo, useEffect, useState } from 'react';
import { AnimationMixer, AnimationClip, Clock } from 'three';
import { useFrame } from 'react-three-fiber';
import { useGetModel } from './helpers/getModel';

import idle from './animations/idle';
import sit from './animations/sit';
import stand from './animations/stand';
import finish from './animations/finish';

import enemyIdle from './animations/enemyIdle';
import enemyAttack from './animations/enemyAttack';
import enemyFinish from './animations/enemyFinish';

export const NPC = memo(({ model, id, scale = [0.02, 0.02, 0.02], state }) => {
  const object = useGetModel(model, id);
  const mixer = new AnimationMixer(object);
  const idleAnimation = mixer.clipAction(AnimationClip.parse(idle));
  const enemyIdleAnimation = mixer.clipAction(AnimationClip.parse(enemyIdle));
  const standAnimation = mixer.clipAction(AnimationClip.parse(stand));
  const sitAnimation = mixer.clipAction(AnimationClip.parse(sit));
  const enemyAttackAnimation = mixer.clipAction(AnimationClip.parse(enemyAttack));
  const enemyFinishAnimation = mixer.clipAction(AnimationClip.parse(enemyFinish));
  const finishAnimation = mixer.clipAction(AnimationClip.parse(finish));

  sitAnimation.clampWhenFinished = true;
  sitAnimation.repetitions = 1;
  standAnimation.clampWhenFinished = true;
  standAnimation.repetitions = 1;
  finishAnimation.clampWhenFinished = true;
  finishAnimation.repetitions = 1;
  enemyFinishAnimation.clampWhenFinished = true;
  enemyFinishAnimation.repetitions = 1;

  useEffect(() => {
    switch (state) {
      case 'picking':
        sitAnimation.play();
        break;
      case 'attacking':
        id === 'enemy' ? enemyAttackAnimation.play() : idleAnimation.play();
        break;
      case 'finish':
        id === 'enemy' ? enemyFinishAnimation.play() : finishAnimation.play();
      break;

      default:
        id === 'enemy' ? enemyIdleAnimation.play() : idleAnimation.play();
    }
  }, [state, mixer, id]);

  const clock = new Clock();
  useFrame(() => {
    mixer.update(clock.getDelta());
  });

  return <primitive object={object} scale={scale} />
});
